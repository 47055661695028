import { template as template_5edebf79727e4cec966848e33b47a872 } from "@ember/template-compiler";
const FKText = template_5edebf79727e4cec966848e33b47a872(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
