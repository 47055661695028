import { template as template_b1316a3c1f1340468b8f38ea4cb69c8c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_b1316a3c1f1340468b8f38ea4cb69c8c(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
