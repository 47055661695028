import { template as template_6a2be74413954951b297a5484bb5df4a } from "@ember/template-compiler";
const FKControlMenuContainer = template_6a2be74413954951b297a5484bb5df4a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
