import { template as template_dc6d4ef1272e49779b3214d0d0341122 } from "@ember/template-compiler";
const WelcomeHeader = template_dc6d4ef1272e49779b3214d0d0341122(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
